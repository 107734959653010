import * as React from "react";
import { inject, observer } from "mobx-react";
import { EGDSToast } from "@egds/react-core/toast";
import { UIPageStore } from "stores/UIPageStore";
import { EGDSIcon } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";

export interface NotificationProps {
  uiPage?: UIPageStore;
}

// Listens to the UIPageStore and display notifications
@inject("uiPage")
@observer
export class Notification extends React.Component<NotificationProps> {
  public render() {
    const { uiPage } = this.props;
    if (!uiPage || !uiPage.notification) {
      return null;
    }

    return (
      <EGDSToast show={uiPage.notification.isVisible}>
        <EGDSSpacing margin={{ inlineend: "three" }}>
          <span>
            <EGDSIcon name={uiPage.notification.icon} />
          </span>
        </EGDSSpacing>
        {uiPage.notification.message}
      </EGDSToast>
    );
  }
}
