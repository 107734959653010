import * as React from "react";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { Footer } from "@gc/global-components";
import { LazyLoad } from "../utility/LazyLoad/LazyLoad";
import { ErrorBoundary } from "components/errorBoundaries/errorBoundaries";

/**
 * Represents the component at the bottom of a Blossom page. It will either use
 * an HTML string (parsed to add UITK styles) from globalcontrols on mobile or the Bernie
 * footer if it is on desktop HTG/F pages
 */
export const FlexFooter = ({ context }: { context: ExtendedContextStore }) => {
  const pageType = context.experienceContext ? context.experienceContext.pageType : context.searchContext.pageType;

  if (pageTypesWithNoFooter.includes(pageType)) {
    return null;
  }

  const isBexStorefrontPage = pageType.indexOf("Storefront") > -1 && context.site.brand === "expedia";
  const isCometStorefrontPage =
    pageType.indexOf("Storefront") > -1 && (context.site.brand === "orbitz" || context.site.brand === "travelocity");

  const marqueeClass = isBexStorefrontPage
    ? "bex-homepage-module"
    : isCometStorefrontPage
    ? "comet-homepage-module"
    : "";

  return (
    <LazyLoad context={context}>
      <EGDSSpacing padding={{ block: "three", inlinestart: "two", inlineend: "two" }}>
        <div>
          <ErrorBoundary key="global-footer" id="global-footer">
            <Footer marqueeClass={marqueeClass} />
          </ErrorBoundary>
        </div>
      </EGDSSpacing>
    </LazyLoad>
  );
};

const pageTypesWithNoFooter = ["Html-Sitemap"];
